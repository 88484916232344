<template>
  <TTView>
    <VRow justify="center">
      <VCol
        lg="6"
        md="6"
        sm="12"
        xs="12"
      >
        <VImg
          class="mt-16"
          :src="require('@/assets/img/br_not_supp.svg')"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <h1 class="mt-10 mb-8">
          {{ $t('browserNotSupported') }}
        </h1>
        <div class="tt-black--text text--lighten-2">
          {{ $t('browserDescription') }}
          <span class="tt-blue--text">
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              class="text-decoration-none"
            >Google Chrome, </a>
            <a
              href="https://www.microsoft.com/edge/"
              target="_blank"
              class="text-decoration-none"
            >Microsoft Edge, </a>
            <a
              href="https://www.mozilla.org/firefox/"
              target="_blank"
              class="text-decoration-none"
            >Mozilla Firefox, </a>
            <a
              href="https://opera.com/"
              target="_blank"
              class="text-decoration-none"
            >Opera, </a>
            <a
              href="https://www.apple.com/safari/"
              target="_blank"
              class="text-decoration-none"
            >Safari. </a>
          </span>
        </div>

        <I18N
          tag="div"
          class="mt-2"
          path="ieWarn"
        >
          <span class="tt-yellow--text text--lighten-1">
            {{ $t('warning') }}
          </span>
        </I18N>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TTView from '@/components/TTView.vue';

export default {
  name: 'BrowserNotSupported',
  components: { TTView },
};
</script>
