import is from 'is_js';

export default function checkBrowser() {
  const isChrome = is.chrome('>=80');
  const isEdge = is.edge(18) || is.edge('>=83');
  const isSafari = is.safari('>=12');
  const isFirefox = is.firefox('>=77');
  const isOpera = is.opera('>=68');

  return is.desktop()
    ? [isChrome, isEdge, isSafari, isFirefox, isOpera].some((ver) => ver)
    : true;
}
