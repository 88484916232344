import Vue from 'vue';
import {
  i18n, router, store, vuetify,
} from '@/plugins';
import App from '@/App.vue';
import '@/plugins/sentry';
import { featureFlagsGet } from '@/services/api/feature-flags';
import { setFeatureFlags } from '@/plugins/vuex/mutationTypes';

Vue.config.productionTip = false;

(async () => {
  try {
    const { data } = await featureFlagsGet();
    store.commit(
      setFeatureFlags,
      data.features.filter((ff) => ff.enabled).map((ff) => ff.name),
    );
  } catch (err) {
    // no-error
  } finally {
    /* eslint-disable-next-line vue/require-name-property */
    new Vue({
      i18n,
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
})();
