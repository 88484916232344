import VuetifyColors from 'vuetify/lib/util/colors';
import TTColors from '@/plugins/vuetify/themes/tt.light.colors';

const theme = {
  ...VuetifyColors,
  ...TTColors,

  // TODO: correct this colors
  primary: TTColors['tt-blue'],
  secondary: TTColors['tt-black'],
  accent: TTColors['tt-blue'],
  error: TTColors['tt-red'],
  warning: TTColors['tt-yellow'],
  info: TTColors['tt-blue'],
  success: TTColors['tt-green'],

  // Note: just string, cant be color object
  anchor: TTColors['tt-blue'].base,
};

export default theme;
