import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

if (['production'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    dsn: 'https://b79e66c9e851b9f25b5f948dba8e0e37@err.t8h.io/15',
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        logErrors: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  });
}
