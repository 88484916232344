<template>
  <VContainer
    v-if="!$store.state.loading"
    class="content-block"
  >
    <VRow :no-gutters="$vuetify.breakpoint.smAndDown">
      <VCol>
        <!-- center content -->
        <!-- Note: content should be wrapped:
          <VRow>
            <VCol>
              CONTENT HERE
            </VCol>
          </VRow>
        -->

        <slot />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'TTView',
};
</script>

<style lang="scss">
.content-block {
  max-width: 420px;
  min-width: 320px;
  width: 100%;
  padding-top: 120px;
}

@media only screen and (min-width: 960px) {
  .content-block {
    max-width: 790px;
  }
}
</style>
