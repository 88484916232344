<template>
  <div id="app">
    <AppProvider v-if="isBrowserSupported">
      <RouterView />
    </AppProvider>

    <DefaultSlotLayout v-else>
      <BrowserNotSupported />
    </DefaultSlotLayout>
  </div>
</template>

<script>
import checkBrowser from '@/helpers/checkBrowser';
import AppProvider from '@/components/app/AppProvider';
import BrowserNotSupported from '@/views/errors/BrowserNotSupported.vue';
import DefaultSlotLayout from '@/layouts/DefaultSlotLayout.vue';

export default {
  name: 'App',

  components: {
    DefaultSlotLayout,
    BrowserNotSupported,
    AppProvider,
  },

  computed: {
    isBrowserSupported() {
      return this.checkBrowser();
    },
  },
  methods: {
    checkBrowser,
  },
};
</script>

<style src="@fortawesome/fontawesome-pro/css/all.css"></style>
<style lang="scss" src="@/styles/main.scss"></style>
