import * as Names from '@/plugins/vue-router/constants';

export default {
  name: 'AppProvider',

  provide: {
    // Note: this remove module symbol and devtools does not think that it is a ES module
    Names: { ...Names },
  },

  render() {
    return this.$slots.default;
  },
};
