export const R_APP = 'app';
export const R_ERROR_404 = 'error-404';

export const R_SURVEY = 'app.survey';
export const R_SURVEY_TEST = 'app.survey.test';

export const R_SURVEY_ORGSTRUCTURE = 'app.user_survey.orgstructure';
export const R_USER_SURVEY_WELCOME = 'app.user_survey.welcome';
export const R_USER_SURVEY = 'app.user_survey';
export const R_USER_SURVEY_PAGE = 'app.user_survey.page';
export const R_USER_SURVEY_END = 'app.user_survey.end';
export const R_USER_SURVEY_LANGUAGES = 'app.user_survey.languages';

export const R_SURVEY_NOT_ACTIVE = 'app.survey.not_active';

// TODO ренеймить когда завезём авториз
export const R_USER_SURVEY_FINISHED = 'app.user_survey.finished';
export const R_USER_SURVEY_ANOTHER_ORGANIZATION = 'app.user_survey.anotherOrganization';
export const R_USER_SURVEY_DO_NOT_DISTURB = 'app.user_survey.do_not_disturb';
