<template>
  <VApp id="tt-app">
    <VMain>
      <slot />
    </VMain>
  </VApp>
</template>

<script>
export default {
  name: 'DefaultSlotLayout',
};
</script>
