import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import * as VuetifyDirectives from 'vuetify/lib/directives';
import * as VuetifyComponents from 'vuetify/lib/components';
import ru from 'vuetify/es5/locale/ru';
import TTLightTheme from '@/plugins/vuetify/themes/tt.light.theme';
import TTDarkTheme from '@/plugins/vuetify/themes/tt.dark.theme';

Vue.use(Vuetify, {
  components: VuetifyComponents,
  directives: VuetifyDirectives,
});

const options = {
  theme: {
    default: 'light',
    themes: {
      light: TTLightTheme,
      dark: TTDarkTheme,
    },
    options: {
      // Note: IE does not support CSS Custom Properties
      customProperties: false,
    },
  },

  icons: {
    iconfont: 'fa',
    values: {
      complete: 'fal fa-check',
      cancel: 'fal fa-times-circle',
      close: 'fal fa-times',
      delete: 'fal fa-times-circle',
      clear: 'fal fa-times-circle',
      success: 'fal fa-check-circle',
      info: 'fal fa-info-circle',
      warning: 'fal fa-exclamation',
      error: 'fal fa-exclamation-triangle',
      prev: 'fal fa-chevron-left',
      next: 'fal fa-chevron-right',
      checkboxOn: 'fal fa-check-square',
      checkboxOff: 'far fa-square',
      checkboxOffLight: 'fal fa-square',
      checkboxIndeterminate: 'fal fa-minus-square',
      delimiter: 'fal fa-circle',
      sort: 'fal fa-sort-amount-up',
      expand: 'fal fa-chevron-down',
      menu: 'fal fa-bars',
      subgroup: 'fal fa-caret-down',
      dropdown: 'fal fa-angle-down',
      radioOn: 'far fa-dot-circle',
      radioOff: 'far fa-circle',
      edit: 'fal fa-edit',
      ratingEmpty: 'far fa-star',
      ratingFull: 'fal fa-star',
      ratingHalf: 'fal fa-star-half',
      loading: 'fal fa-sync',
      first: 'fal fa-step-backward',
      last: 'fal fa-step-forward',
      unfold: 'fal fa-arrows-alt-v',
      file: 'fal fa-paperclip',
      plus: 'fal fa-plus',
      minus: 'fal fa-minus',
      angryLight: 'fal fa-angry',
      frownLight: 'fal fa-frown',
      laughLight: 'fal fa-laugh',
      mehLight: 'fal fa-meh',
      grinBeamLight: 'fal fa-grin-beam',
      grinHeartsLight: 'fal fa-grin-hearts',
      imageLight: 'fal fa-image',
      smileLight: 'fal fa-smile',
      search: 'fal fa-search',
      arrowLeftLight: 'fal fa-arrow-left',
      circleSolid: 'fas fa-circle',
      exclamationCircle: 'fas fa-exclamation-circle',
    },
  },

  lang: {
    locales: { ru },
    current: 'ru',
  },
};

export default new Vuetify(options);
