const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const YANDEX_ID = process.env.VUE_APP_YANDEX_ID;

if (IS_PRODUCTION) {
  /* eslint-disable */
  (function (m, e, t, r, i, k, a) {
    m[i] =
      m[i] ||
      function () {
        (m[i].a = m[i].a || []).push(arguments);
      };
    m[i].l = 1 * new Date();
    (k = e.createElement(t)),
      (a = e.getElementsByTagName(t)[0]),
      (k.async = 1),
      (k.src = r),
      a.parentNode.insertBefore(k, a);
  })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
  /* eslint-enable */

  window.ym(YANDEX_ID, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  });
}

function yandexMetrica(to) {
  if (IS_PRODUCTION) {
    const { path } = to;
    window.ym(YANDEX_ID, 'hit', path);
  }
}

export default yandexMetrica;
