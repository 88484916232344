import * as Names from '@/plugins/vue-router/constants';

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'errors/404',
        name: Names.R_ERROR_404,
        component: () => import(/* webpackChunkName "errors.404" */ '@/views/errors/PageNotFound.vue'),
      },
    ],
  },

  {
    path: '/app',
    name: Names.R_APP,
    component: () => import('@/layouts/app/AppLayout.vue'),
    children: [
      {
        path: 'survey/:slug',
        name: Names.R_SURVEY,
        components: {
          default: () => import('@/components/SurveyTechPage.vue'),
        },
      },
      {
        path: 'survey/:slug/not_active',
        name: Names.R_SURVEY_NOT_ACTIVE,
        components: {
          default: () => import('@/components/stubs/SurveyNotActiveStub.vue'),
        },
      },
      {
        path: 'survey/:slug/test',
        name: Names.R_SURVEY_TEST,
        components: {
          default: () => import('@/components/SurveyTestTechPage.vue'),
        },
      },
      {
        path: 'user_survey/:slug/orgstructure',
        name: Names.R_SURVEY_ORGSTRUCTURE,
        components: {
          default: () => import('@/views/SurveyOrgstructure.vue'),
        },
      },

      // TODO refactor убрать когда закончатся активные опросы по старым ссылкам
      {
        path: 'user_survey/:slug/welcome',
        name: Names.R_USER_SURVEY_WELCOME,
        redirect: { name: Names.R_USER_SURVEY },
      },
      {
        path: 'user_survey/:slug',
        name: Names.R_USER_SURVEY,
        components: {
          default: () => import('@/components/UserSurveyTechPage.vue'),
        },
      },
      {
        path: 'user_survey/:slug/languages',
        name: Names.R_USER_SURVEY_LANGUAGES,
        components: {
          default: () => import('@/views/UserSurveyLanguages.vue'),
        },
      },
      {
        path: 'user_survey/:slug/page',
        name: Names.R_USER_SURVEY_PAGE,
        components: {
          default: () => import('@/views/questions/SurveyPage.vue'),
        },
      },
      {
        path: 'user_survey/:slug/end',
        name: Names.R_USER_SURVEY_END,
        components: {
          default: () => import('@/views/UserSurveyEnd.vue'),
        },
      },
      {
        path: 'user_survey/:slug/finished',
        name: Names.R_USER_SURVEY_FINISHED,
        components: {
          default: () => import('@/views/SurveyFinished.vue'),
        },
      },
      {
        path: 'user_survey/:slug/another_organization',
        name: Names.R_USER_SURVEY_ANOTHER_ORGANIZATION,
        components: {
          default: () => import('@/views/SurveyAnotherOrganization.vue'),
        },
      },
      {
        path: 'user_survey/:slug/do_not_disturb',
        name: Names.R_USER_SURVEY_DO_NOT_DISTURB,
        components: {
          default: () => import('@/views/UserSurveyDoNotDisturb.vue'),
        },
      },
    ],
  },

  {
    path: '*',
    redirect: { name: Names.R_ERROR_404 },
  },
];

export default routes;
