import { HTTP_GET } from '@/services/api/constants';
import transport from '@/services/api/feature-flags/axios.transport';

/**
 * Гайд по именованию ресурсов
 * https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 *
 */

const ffId = process.env.VUE_APP_FEATURE_FLAGS_INSTANCE_ID || '';
const ffMode = process.env.VUE_APP_FEATURE_FLAGS_MODE || '';

export const featureFlagsGet = (data = {}, options) => transport({
  url: 'features',
  method: HTTP_GET,
  headers: {
    'unleash-appname': ffMode,
    'unleash-instanceid': ffId,
  },
  data,
  ...options,
});

export default { featureFlagsGet };
