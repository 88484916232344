const TTColors = {
  'tt-black': {
    base: '#0B1218' /* Black / Black 100 */,
    lighten1: '#1F252A', /* White / White 8 */
    lighten2: '#32383D', /* White / White 16 */
    lighten3: '#464B4F', /* White / White 24 */
    lighten4: '#7B7F82', /* White / White 46 */
    lighten5: '#A7AAAC', /* White / White 64 */
    lighten6: '#FFFFFF', /* White / White 100 */
  },

  'tt-white': {
    base: '#FFFFFF', /* White / White 100 */
    darken1: '#A7AAAC', /* White / White 64 */
    darken2: '#7B7F82', /* White / White 46 */
    darken3: '#464B4F', /* White / White 24 */
    darken4: '#32383D', /* White / White 16 */
    darken5: '#1F252A', /* White / White 8 */
    darken6: '#0B1218' /* Black / Black 100 */,
  },

  'tt-red': {
    base: '#EF323F' /* Red / Dark Red */,
    lighten1: '#FF2B3E' /* Red / Red */,
    lighten2: '#FF4754' /* Red / Light Red */,
    lighten3: '#FFE2E2' /* Red / Extra Light Red */,
  },

  'tt-orange': {
    base: '#FF3E00' /* Orange / Dark Orange */,
    lighten1: '#FF6E37' /* Orange / Orange */,
    lighten2: '#FF9974' /* Orange / Light Orange */,
    lighten3: '#FFEAE3' /* Orange / Extra Light Orange */,
  },

  'tt-yellow': {
    base: '#FFB500' /* Yellow / Dark Yellow */,
    lighten1: '#FFC700' /* Yellow / Yellow */,
    lighten2: '#FFD340' /* Yellow / Light Yellow */,
    lighten3: '#FFF6DC' /* Yellow / Extra Light Yellow */,
  },

  'tt-green': {
    base: '#00A345' /* Green / Dark Green */,
    lighten1: '#00D358' /* Green / Green */,
    lighten2: '#00F170' /* Green / Light Green */,
    lighten3: '#D4F8E7' /* Green / Extra Light Green */,
  },

  'tt-blue': {
    base: '#00A0F2' /* Blue / Dark Blue */,
    lighten1: '#00B8FF' /* Blue / Blue */,
    lighten2: '#6AD9FF' /* Blue / Light Blue */,
    lighten3: '#D9F5FF' /* Blue / Extra Light Blue */,
  },

  'tt-purple': {
    base: '#601EF3' /* Purple / Dark Purple */,
    lighten1: '#8455F8' /* Purple / Purple */,
    lighten2: '#A886FB' /* Purple / Light Purple */,
    lighten3: '#EDE5FE' /* Purple / Extra Light Purple */,
  },

  'tt-pink': {
    base: '#FF00D7' /* Pink / Dark Pink */,
    lighten1: '#FF26E1' /* Pink / Pink */,
    lighten2: '#FF70EA' /* Pink / Light Pink */,
    lighten3: '#FFE2FC' /* Pink / Extra Light Pink */,
  },
};

export default TTColors;
