import Vue from 'vue';
import VueRouter from 'vue-router';
import createRouter from '@/plugins/vue-router/router';
import routes from '@/plugins/vue-router/routes';
import yandexMetrica from '@/plugins/vue-router/yandexMetrica';

Vue.use(VueRouter);

const { BASE_URL } = process.env;

const router = createRouter({
  baseUrl: BASE_URL,
  routes,
});

router.afterEach(yandexMetrica);

export * from '@/plugins/vue-router/constants';
export default router;
